.container {
    display: flex;
    flex-direction: column;
    max-width: 900px;
}

.pageBody p {
    margin-top: 10px;
    font-size: 23px;
}

.pageDate,
.pageBack {
    margin-top: 5px;
    font-size: 24px;
}

.pageBack:hover {
    color: gold;
}


@media (max-width : 500px) {
    .pageBody p {
        padding: 5px;
        font-size: 20px;
    }

    .pageDate,
    .pageBack {
        padding: 5px;
        margin-top: 5px;
        font-size: 20px;
    }
}