body {
  background: white;
}

#root {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  place-items: center;
  font-family: 'Poppins', sans-serif;
  background: white;
  background-size: cover;
  background-attachment: fixed;
}